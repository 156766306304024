const initState = {
  testcol: [
    { id: 1, title: "" }
  ]
}

const dropdownReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_DD':
      console.log('created dropdown', action.entry.name)
      return state

    case 'CREATE_DD_ERROR':
      console.log('create dropdown error', action.err)
      return state
    
    case 'DELETE_DD':
      console.log('deleted dropdown', action.id)
      return state

    case 'DELETE_DD_ERROR':
      console.log('delete dropdown error', action.err)
      return state  

    case 'UPDATE_DD':
      console.log('updated dropdown', action.entry.name)
      return state

    case 'UPDATE_DD_ERROR':
      console.log('update dropdown error', action.err)
      return state 

    default:
     return state
  }
}

export default dropdownReducer