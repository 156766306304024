import React, { Component } from 'react'
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions'

class SignedInLinks extends Component {
  state = {
    show: false
  }

  handleToggleModal = () => {
    this.setState({ show: !this.state.show })
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  refOut = React.createRef()

  handleClickOutside = e => {
    if (!this.refOut.current.contains(e.target)) {
      this.setState({ show: false })
    }
  }

  render() {
    const { initials, first_name, last_name } = this.props.profile
    const { email, uid } = this.props.auth
    const { show } = this.state

    return (
      <ul className="header--flex-wrap">
        { /* <li className="header--items" onClick={props.signOut}>Log Out</li> */}
        <div ref={this.refOut}>
          <button className="button--initials" onClick={this.handleToggleModal}>{initials && initials}</button>
          {
            show
              ?

              <div className="button--initials-modal display-block">
                <li className="lato button--initials-modal-t-n">{first_name + " " + last_name}</li>
                <li className="button--initials-modal-t-e">{email}</li>

                <button className="header--items button-right" onClick={() => this.props.signOut(uid)}>Log Out</button>
              </div>

              :
              null
          }
        </div>
      </ul>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: (uid) => dispatch(signOut(uid))
  }
}

export default connect(null, mapDispatchToProps)(SignedInLinks);