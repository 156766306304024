const initState = {
  timeline_state: [
    { width: 0 }
  ]
}

const timelineReducer = (state = initState, action) => {
  return state
}

export default timelineReducer