import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'

// Initialize Firebase
var config = {
  apiKey: "AIzaSyD8VfJG9bW9auGk3TbI6uKC0MNuhim6NQM",
  authDomain: "srmc-gantt.firebaseapp.com",
  databaseURL: "https://srmc-gantt.firebaseio.com",
  projectId: "srmc-gantt",
  storageBucket: "srmc-gantt.appspot.com",
  messagingSenderId: "42231834166"
};

firebase.initializeApp(config);

// For user creation without signout
export const secondaryApp = firebase.initializeApp(config, "Secondary");

const rtdb = firebase.database()

export function setupPresence(user) {
  const isOfflineForRTDB = {
    state: 'offline',
    lastChanged: firebase.database.ServerValue.TIMESTAMP
  }

  const isOnlineForRTDB = {
    state: 'online',
    lastChanged: firebase.database.ServerValue.TIMESTAMP
  }

  if (!user.isEmpty && user.uid) {
    const rtdbRef = rtdb.ref('/status/' + user.uid)

    rtdb.ref(".info/connected").on("value", async snapshot => {
      if (snapshot.val() === false) {
        return
      }

      await rtdbRef.onDisconnect().set(isOfflineForRTDB)
      rtdbRef.set(isOnlineForRTDB)
    })
  }
}

export function visibilityPresence(user, visibility) {
  const isOnlineForRTDB = {
    state: 'online',
    lastChanged: firebase.database.ServerValue.TIMESTAMP
  }
 
  const isAwayForRTDB = {
    state: 'away',
    lastChanged: firebase.database.ServerValue.TIMESTAMP
  }

  const rtdbRef = rtdb.ref('/status/' + user.uid)

  if (visibility === 'hidden') {
    rtdbRef.set(isAwayForRTDB)
  } else {
    rtdbRef.set(isOnlineForRTDB)
  }
}

export default firebase