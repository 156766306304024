const initState = {
  itemlist: [
    { id: 1, title: "" }
  ]
}

const itemlistReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_STATUS':
      console.log('status updated', action.job)
      return state

    case 'UPDATE_STATUS_ERROR':
      console.log('status update error', action.err)
      return state

    case 'UPDATE_EX_STATUS':
      console.log('existing status updated', action.job)
      return state

    case 'UPDATE_EX_STATUS_ERROR':
      console.log('existing status update error', action.err)
      return state

    default:
      return state
  }
}

export default itemlistReducer