import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar'; // Importing Navbar component
import SignIn from './components/auth/SignIn';
import LoadingScreen from './components/dashboard/content/Timeline/LoadingScreen';

// Lazy loading components for route-based code splitting
const Auth_Content = lazy(() => import(/* webpackChunkName: "auth_content" */  './components/auth_dash/Auth_Content'))
const Content = lazy(() => import(/* webpackChunkName: "content" */  './components/dashboard/Content'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Suspense fallback={<LoadingScreen />} >
            <Switch>
              <Route exact path='/signin' component={SignIn} />
              <Route exact path='/admin' component={Auth_Content} />
              <Route path='/' component={Content} />
            </Switch>
          </Suspense>

          <footer className="nav-wrapper">
            <div className="row">
              <div className="col s12">
                <ul className="right black-text">
                  © 2020 V. 1.3.0
                                  </ul>
              </div>
            </div>
          </footer>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;