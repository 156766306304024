import authReducer from './authReducer'
import entryReducer from './entryReducer'
import dropdownReducer from './dropdownReducer'
import userReducer from './userReducer'
import itemlistReducer from './itemlistReducer'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'
import timelineReducer from './timelineReducer'
import tlheaderReducer from './tlheaderReducer'
import boardReducer from './boardReducer'
import incidentReducer from './incidentReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  entry: entryReducer,
  drop: dropdownReducer,
  user: userReducer,
  itemlist: itemlistReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  timeline: timelineReducer,
  tldates: tlheaderReducer,
  board: boardReducer,
  incident: incidentReducer
});

export default rootReducer