import React, { Component } from 'react'

class LoadingScreen extends Component {
  render() {
    return (
      <div className="tl--area">
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    )
  }
}

export default LoadingScreen