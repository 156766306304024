import { secondaryApp } from "../../config/fbConfig";

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then(() => {
      dispatch({ type: 'LOGIN_SUCCESS' });
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err });
    });
  }
}

export const signOut = (uid) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const isOfflineForRTDB = {
      state: 'offline',
      lastChanged: firebase.database.ServerValue.TIMESTAMP
    }

    const rtdbRef = firebase.database().ref('/status/' + uid)

    firebase.database().ref(".info/connected").on("value", async snapshot => {
      if (snapshot.val() === false) {
        return
      }

      await rtdbRef.set(isOfflineForRTDB)
    })

    firebase.auth().signOut().then(() => {
      firebase.logout()
      dispatch({ type: 'SIGNOUT_SUCCESS' });
    })
  }
}

export const signUp = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    // creating user through secondary auth
    secondaryApp.auth().createUserWithEmailAndPassword(
      user.email,
      user.password
    ).then(resp => {
      const obj = {
        type: user.type,
        first_name: user.first_name,
        last_name: user.last_name,
        initials: user.initials,
        edit_rights: false,
        view_rights: false,
        read_rights: false,
        mwd_rights: false,
        rev_rights: false,
        fin_rights: false,
        m_stats_rights: false,
        sup_rights: false,
      }
      secondaryApp.auth().signOut()

      firestore.collection('users').doc(resp.user.uid).set(obj);
    }).then(() => {
      dispatch({ type: 'SIGNUP_SUCCESS' });
    }).catch((err) => {
      dispatch({ type: 'SIGNUP_ERROR', err });
    });
  }
}