// Navbar is stateless, so it is functional component, not a class component
import React from 'react';
import { Link } from 'react-router-dom';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import { connect } from 'react-redux'
import SignedInLinksAuth from './SignedInLinksAuth';

// Creating the function
const Navbar = (props) => {
  const { auth, profile } = props;

  const links = auth.uid
    ?
    (
      profile.sup_rights ?
        <SignedInLinksAuth profile={profile} auth={auth} />
        :
        <SignedInLinks profile={profile} auth={auth} />
    )
    : <SignedOutLinks />

  return (
    <nav className="header shadow sticky">
      <div className="header--flex-wrap">
        <ul className="header--ul">
          <Link to='/'>
            <li className="header--logo">
              <span className="header--logo--text-sm">SRMC</span>
              <span className="header--logo--text-l">GANTT</span>
            </li>
          </Link>
        </ul>

        {links}
      </div>
    </nav>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default connect(mapStateToProps)(Navbar)