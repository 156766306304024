import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'

class SignIn extends Component {
  state = {
    email: '',
    password: ''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state)
  }

  render() {
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to='/' />

    return (
      <div className="modal-bg display-block modal-bg-fill-w">
        <form className="modal modal-w-sign" onSubmit={this.handleSubmit}>
          <h2>Sign In</h2>
          <div className="modal-grouped">
            <div>
              <label className="modal-grouped-label" htmlFor="email">Email</label>
              <input className="modal-grouped-input" type="email" id="email" onChange={this.handleChange} />
            </div>
          </div>
          <div className="modal-grouped">
            <div>
              <label className="modal-grouped-label" htmlFor="password">Password</label>
              <input className="modal-grouped-input" type="password" id="password" onChange={this.handleChange} />
            </div>
          </div>
          <div className="modal-grouped">
            <button >Login</button>
            {authError ? <p className="failed-login">{authError}</p> : null}
          </div>
            
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)