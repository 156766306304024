import React from 'react';
import { NavLink } from 'react-router-dom';

// Creating the function
const SignedOutLinks = () => {
  return (
    <ul className="right">
      <li><NavLink to='/signin'>Login</NavLink></li>
    </ul>
  )
}

export default SignedOutLinks;