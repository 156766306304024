const initState = {
    userData: [
      { id: 1, title: "" }
    ]
  }
  
  const userReducer = (state = initState, action) => {
    switch (action.type) {
      case 'USER_OFFLINE':
        console.log('user offline', action.user)
        return state
  
      case 'USER_OFFLINE_ERROR':
        console.log('user offline error', action.err)
        return state
     
      case 'USER_UPDATED':
        console.log('user updated', action.id)
        return state
  
      case 'USER_UPDATE_ERROR':
        console.log('user offline error', action.err)
        return state

      default:
       return state
    }
  }
  
  export default userReducer