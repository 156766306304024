const initState = {
  itemlist: [
    { id: 1, title: "" }
  ]
}

const boardReducer = (state = initState, action) => {
  switch (action.type) {
    case 'MWD_BOARD_ADD':
        console.log('item added', action.entry)
        return state
  
    case 'MWD_BOARD_ADD_ERROR':
      console.log('item add error', action.err)
      return state

    case 'MWD_BOARD_UPDATE':
      console.log('item updated', action.id)
      return state

    case 'MWD_BOARD_UPDATE_ERROR':
      console.log('item update error', action.err)
      return state

    case 'MWD_BOARD_DELETE':
      console.log('item deleted', action.d)
      return state

    case 'MWD_BOARD_DELETE_ERROR':
      console.log('item delete error', action.err)
      return state

    case 'MWD_BOARD_TIME':
      console.log('timestamp', action.obj)
      return state

    case 'MWD_BOARD_TIME_ERROR':
      console.log('timestamp error', action.err)
      return state

    default:
      return state
  }
}

export default boardReducer