const initState = {
  testcol: [
    { id: 1, title: "" }
  ]
}

const entryReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_ENTRY':
      console.log('created entry', action.entry)
      return state

    case 'CREATE_ENTRY_ERROR':
      console.log('create entry error', action.err)
      return state

    case 'UPDARE_ENTRY':
      console.log('updated entry', action.entry.local_id)
      return state

    case 'UPDARE_ENTRY_ERROR':
      console.log('update entry error', action.err)
      return state
    
    case 'DELETE_ENTRY':
      console.log('deleted entry', action.id)
      return state

    case 'DELETE_ENTRY_ERROR':
      console.log('delete entry error', action.err)
      return state  

    default:
     return state
  }
}

export default entryReducer