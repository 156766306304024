const initState = []

const tlheaderReducer = (state = initState, action) => {
  switch (action.type) {
    case "RESET_DATES":
      return initState;
    case "ADD_DATES":
      return [...state, action.day.getTime()]
    default:
      return state
  }
}

export default tlheaderReducer