const initState = {
  itemlist: [
    { id: 1, title: "" }
  ]
}

const incidentReducer = (state = initState, action) => {
  switch (action.type) {
    case 'INCIDENT_ADD':
        console.log('incident added', action.entry)
        return state
  
    case 'INCIDENT_ADD_ERROR':
      console.log('incident add error', action.err)
      return state

    case 'INCIDENT_UPDATE':
      console.log('incident updated', action.id)
      return state

    case 'INCIDENT_UPDATE_ERROR':
      console.log('incident update error', action.err)
      return state

    case 'INCIDENT_DELETE':
      console.log('incident deleted', action.d)
      return state

    case 'INCIDENT_DELETE_ERROR':
      console.log('incident delete error', action.err)
      return state

    case 'INC_PAGINATION_UPDATE':
      console.log('incident pagination updated')
      return state

    case 'INC_PAGINATION_UPDATE_ERROR':
      console.log('incident pagination update error', action.err)
      return state

    default:
      return state
  }
}

export default incidentReducer